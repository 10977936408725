import React from 'react'
import Layout from '../../components/Layout'
import Grid from '@mui/material/Grid';
import {Helmet} from 'react-helmet'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Breadcrumbs, makeStyles } from '@mui/material';
import Sempro from '../../images/sempro.png'
import Semgo from '../../images/semgo.png'
import Companion from '../../images/companion.png'
import Hunter from '../../images/hunter.png'
import d365 from '../../images/d365.png'
import Powerbi from '../../images/powerbi.png'
import Powerapps from '../../images/powerapps.png'
import {Link} from '@mui/material';


// get the props data, pageContext, and location
export default function Components({location} : {location: Location}) {


    return (
        <Layout 
          location={location}
        >
          <Helmet title={`SEM Wiki - Components`} defer={false}/>    
            <Grid
            item
            container
            direction="row"
            justifyContent="space-evenly"
            style={{ paddingTop: 40 , width: '100%'}}
            >   
                <Grid item sx={{
                    marginTop: '40px', marginLeft: '7.69%', marginRight: '15.38%', width: '100%', paddingBottom: "70px"
                }} component='article'>
                    <Breadcrumbs separator="/">
                        <Link href="/" underline='none' style={{color:'#008200', fontSize: '14px', fontWeight: 400}}>
                            Home
                        </Link>
                        <Link href="#" underline='none' style={{color:'#008200', fontSize: '14px', fontWeight: 400}}>
                            What is SEM?
                        </Link>
                        <Typography style={{fontSize: '14px', fontWeight: 400}}>
                            Components / Features
                        </Typography>
                    </Breadcrumbs>
                    <Typography sx={{fontSize: "32px", lineHeight: "36.48px", color: '#333333',fontWeight: '700', marginTop: "58px"}} gutterBottom>
                        Components
                    </Typography>
                    <Grid item style={{display:'flex', flexDirection:'row', gap:'1.5%', marginBottom: '60px', marginTop: '60px'}}>
                        <Box style={{width:'100%', display: 'flex', flexDirection:'column'}}>
                            <img src={Sempro} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    SEM Pro
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    The front-end used by sales professionals daily
                                </Typography>
                                <Link href="https://install.appcenter.ms/orgs/hsemazurenot/apps/Heineken-SEM-Windows-10-desktop" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}>
                            <img src={Semgo} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    SEM Go
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    The front-end on mobile applications, used by sales professionals daily.
                                </Typography>
                                <Link href="#" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}>
                            <img src={Companion} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    Companion App
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    For sales reps to make and suggest data changes.
                                </Typography>
                                <Link href="#" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}>
                            <img src={Hunter} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    Hunter App
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    A mobile app that will help Sales Representatives identify nearby Prospects easier.
                                </Typography>
                                <Link href="#" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item style={{display:'flex', flexDirection:'row', gap:'1.5%'}}>
                        <Box style={{width:'100%'}}>
                            <img src={d365} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    CRM - Dynamics 365
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    The back-end, control panel used by key users daily.
                                </Typography>
                                <Link href="https://dynamics.microsoft.com/en-us/" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}>
                            <img src={Powerbi} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    Reporting in Power BI
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    SEM captures a lot of data that is crucial to daily sales operations – analyse it in Power BI.
                                </Typography>
                                <Link href="https://app.powerbi.com/" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}>
                            <img src={Powerapps} style={{width:'100%'}}/>
                            <Box style={{padding: '8px', display: 'flex', flexDirection:'column'}}>
                                <Typography style={{fontWeight: 700, color: "#666666", fontSize: '14px'}}>
                                    Power Apps
                                </Typography>
                                <Typography style={{fontWeight: 400, color: "#666666", fontSize: '12px'}}>
                                    Still in experimental phase, but open to development of and integration with PowerApps.
                                </Typography>
                                <Link href="https://apps.powerapps.com/" style={{fontWeight: 400, color: "#008200", fontSize: '12px'}}>
                                    Link here
                                </Link>
                            </Box>
                        </Box>
                        <Box style={{width:'100%'}}></Box>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}
